import {Row , Col} from 'react-bootstrap';
import Baba from '../../assets/Baba/ValiSheikhlar.jpg';
import TimeSinceDeath from './TimeSinceDeath'; // وارد کردن کامپوننت جدید

export default function ValiSheikhlar() {
    const deathDate = new Date('2025-01-21T11:30:59'); // تاریخ فوت پدر
  return (
    <div className='w-100  h-100 d-inline-block  pt-5'>
        <h2 className='mt-5 text-white'>شادروان ولی شیخلر</h2>
        <Row className='container  m-auto  text-light'>
            <Col lg={6} className=' text-start mt-5 p-4'>
                <img src={Baba} alt="Vali Sheikhlar" className='img-fluid rounded'/>
            </Col>
            <Col lg={6} className=' text-start mt-5 p-4'>
                {/* قرار دادن کد های تاریخ */}
                <TimeSinceDeath deathDate={deathDate} /> {/* استفاده از کامپوننت */}

                <div className='mt-5 text-center h4 fontNastaligh'>
                    <p>صد بار خدا مرثیه خوان کرد مرا</p>
                    <p>در بوته صبر امتحان کرد مرا</p>
                    <p>هرگز نشکست پشتم از هیچ غمی</p>
                    <p>جز مرگ پدر که ناتوان کرد مرا</p>
                </div>
                
            </Col>

            
        </Row>
    </div>
  )
}
