import Home from "../Pages/Home/Home";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";
import Projects from "../Pages/Projects/Projects";
import ProjectParams from "../Pages/ParamsPages/ProjectParams";
import ImagesPage from "../Pages/Images/ImagesPage";

// Contact Private
import ContactPrivate from "../Pages/PrivatePages/Contact/ContactPrivate";


// Vali Sheikhlar (Pedar || Baba)
import ValiSheikhlar from "../Pages/ValiSheikhlar/ValiSheikhlar";

const routes  = [
    { path:'/' , element:<Home />},
    { path:'/about' , element:<About />},
    { path:'/contact' , element:<Contact />},
    { path:'/projects' , element:<Projects />},
    { path:'/projectparams/:itemID' , element:<ProjectParams />},
    // Private pages
    { path:'/PrivatePages/Younes', element: <ContactPrivate />},
    {path: '/imagesPage' , element:<ImagesPage />},
    {path: '/valiSheikhlar' , element:<ValiSheikhlar />}

]

export default routes