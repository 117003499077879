import React, { useState, useEffect } from 'react';

function TimeSinceDeath({ deathDate }) {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const diff = now - deathDate;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365.25);
  const months = Math.floor((days % 365.25) / 30.44);

  return (
    <div>
      <h2>سال: {years}</h2>
      <h2>ماه: {months}</h2>
      <h2>روز: {Math.floor(days % 30.44)}</h2>
      <h2>ساعت: {String(hours % 24).padStart(2, '0')}</h2>
      <h2>دقیقه: {String(minutes % 60).padStart(2, '0')}</h2>
      <h2>ثانیه: {String(seconds % 60).padStart(2, '0')}</h2>
    </div>
  );
}

export default TimeSinceDeath;